import { useState, useRef, useEffect } from "react";

import {
  PagenationDataType,
  CategoryDataType,
  PageType,
} from "../../utils/baseTypes";
import { InsightElement } from "./InsightElement";
import { InsightsModuleType } from "./Insights";
import SwiperType from "swiper/swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface InsightSliderProp {
  data: InsightsModuleType;
  InsightData: PagenationDataType;
  InsightCategoryData: CategoryDataType[];
  InsightHighlightedPosts: PageType[];
}

export const InsightSlider = ({
  InsightCategoryData,
  InsightData,
  InsightHighlightedPosts,
}: InsightSliderProp) => {
  const [insightData, setInsightData] = useState<PageType[]>([]);
  const [categoryPath, setCategoryPath] = useState<string>("");

  const swiperRef = useRef<SwiperType>();

  useEffect(() => {
    if (InsightCategoryData.length > 0) {
      const first = InsightCategoryData[0].link.replace(/\/$/, "");
      const path = first.substring(0, first.lastIndexOf("/"));
      setCategoryPath(path);
    }
  }, [InsightCategoryData]);

  useEffect(() => {
    setInsightData(
      Array.isArray(InsightData.posts)
        ? InsightHighlightedPosts.concat(InsightData.posts)
        : InsightHighlightedPosts
    );

    if (swiperRef.current) {
      swiperRef.current.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [InsightData]);

  return (
    <div className="column is-12">
      <Swiper
        slidesPerView={3.5}
        spaceBetween={20}
        loop
        centeredSlides
        centeredSlidesBounds
        observer
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
          },
          766: {
            slidesPerView: 2.5,
          },
          1440: {
            slidesPerView: 3.5,
          },
        }}
      >
        {insightData.map((insight, i) => {
          return (
            <SwiperSlide key={i}>
              <InsightElement
                insight={insight}
                index={i}
                categoryPath={categoryPath}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
