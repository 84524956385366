import { useState } from "react";
import { PageType } from "../../utils/baseTypes";
import { getRelativePathFromLink } from "../../utils/url";
import Link from "next/link";
import { sanitize } from "isomorphic-dompurify";
import { unlinkWiki } from "../../utils/unlinkWiki";
import Image from "next/image";
import dayjs from "dayjs";

import Arrow from "../../images/arrow_dark.svg";
import useWindowSize from "../../hooks/useWindowSize";
import { motion } from "framer-motion";
import { CustomLink } from "../Global/CustomLink";

const MotionLink = motion(Link);

interface InsightElementBigProp {
  insight: PageType;
  index: number;
  categoryPath: string;
}

export const InsightElementBig = ({
  insight,
  index,
}: InsightElementBigProp) => {
  const insight_ = insight.acf?.header_insights!;
  const categories = insight._embedded
    ? insight._embedded["wp:term"].flat()
    : [];

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const { width } = useWindowSize();

  const ImageJSX = (
    <MotionLink href={getRelativePathFromLink(insight.link)}>
      <div
        className={`is-clipped preview-image-big ${
          imageLoaded ? "" : "loading"
        }`}
      >
        <Image
          src={insight_.preview_image.url!}
          alt={insight_.preview_image.alt}
          fill
          onLoadingComplete={() => setImageLoaded(true)}
        />
      </div>
    </MotionLink>
  );

  const InformationJSX = (
    <div className="info-wrapper">
      <div className="main-info">
        <div className="categories">
          {categories.map((category, i) => {
            return (
              <CustomLink
                key={i}
                href={getRelativePathFromLink(category.acf.link)}
                target="_self"
                disablePrefetch
              >
                <span className="category">{category.name}</span>
              </CustomLink>
            );
          })}
        </div>
        <Link
          prefetch={false}
          href={getRelativePathFromLink(insight.link)}
          className="insight-link"
          aria-label="insight-link"
        >
          <span
            className="h3 title"
            dangerouslySetInnerHTML={{
              __html: sanitize(unlinkWiki(insight_.title)),
            }}
          ></span>
          <div
            className="excerpt mt-4"
            dangerouslySetInnerHTML={{ __html: sanitize(insight_.excerpt) }}
          />
        </Link>
      </div>
      <div className="autor-info">
        <span className="info">
          <span>
            {insight_.show_updated_datum
              ? insight_.updated_datum
              : dayjs(insight.date_gmt).format("DD.MM.YYYY")}
          </span>
          &nbsp;|&nbsp;
          <span>
            Autor:&nbsp;
            <Link href={`/autor/${insight_.author.post_name}`} prefetch={false}>
              {insight_.author.post_title}
            </Link>
          </span>
        </span>

        <Link
          prefetch={false}
          href={getRelativePathFromLink(insight.link)}
          className="arrow insight-link"
          aria-label="insight-link"
        >
          <Arrow width={22.06} height={14.58} />
        </Link>
      </div>
    </div>
  );

  const toogle = width > 1215 ? index % 2 === 0 : true;

  const columnOneClass = toogle
    ? "column is-8-tablet is-7-widescreen"
    : "column is-4-widescreen";
  const columnTwoClass = toogle
    ? "column is-offset-4-tablet is-8-tablet  is-offset-1-widescreen is-4-widescreen"
    : "column is-offset-1-widescreen is-7-widescreen";

  return (
    <div className="insight-element-big columns is-multiline">
      <div className={columnOneClass}>{toogle ? ImageJSX : InformationJSX}</div>
      <div className={columnTwoClass}>{toogle ? InformationJSX : ImageJSX}</div>
    </div>
  );
};
