import React, { useEffect, useState } from "react";
import {
  CategoryDataType,
  PageType,
  PagenationDataType,
} from "../../utils/baseTypes";
import { InsightsModuleType } from "./Insights";
import { Button } from "../Global/Button";
import Link from "next/link";
import { getRelativePathFromLink } from "../../utils/url";
import { sanitize } from "isomorphic-dompurify";
import { unlinkWiki } from "../../utils/unlinkWiki";
import Arrow from "../../images/arrow_dark.svg";
import { motion } from "framer-motion";
import { blur_opacity_variant } from "../../utils/transition";
import { CustomLink } from "../Global/CustomLink";
interface InsightListProp {
  data: InsightsModuleType;
  InsightData: PagenationDataType;
  Headline: React.ReactNode;
  InsightCategoryData: CategoryDataType[];
  InsightHighlightedPosts: PageType[];
}

export const InsightList = ({
  Headline,
  InsightData,
  data,
  InsightCategoryData,
  InsightHighlightedPosts,
}: InsightListProp) => {
  const { link } = data.insights;

  const [insightData, setInsightData] = useState<PageType[]>([]);

  useEffect(() => {
    if (InsightHighlightedPosts.length > 0) {
      setInsightData(InsightHighlightedPosts);
    } else {
      setInsightData(InsightData.posts);
    }

    // setInsightData(
    //   Array.isArray(InsightData.posts)
    //     ? InsightHighlightedPosts.concat(InsightData.posts)
    //     : []
    // );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [InsightData]);

  const [categoryPath, setCategoryPath] = useState<string>("");
  useEffect(() => {
    if (InsightCategoryData.length > 0) {
      const first = InsightCategoryData[0].link.replace(/\/$/, "");
      const path = first.substring(0, first.lastIndexOf("/"));
      setCategoryPath(path);
    }
  }, [InsightCategoryData]);
  return (
    <>
      <div className="column is-12 is-4-desktop">
        {Headline}
        {link && (
          <motion.div
            className="is-hidden-touch"
            variants={blur_opacity_variant}
          >
            <Button
              type="primary"
              title={link.title}
              target={link.target}
              href={link.url}
            ></Button>
          </motion.div>
        )}
      </div>
      {/* <div className="column is-12-mobile is-offset-3-tablet is-9-tablet is-offset-1-desktop is-7-desktop insight-list-element-wrapper"> */}
      <div className="column is-12 is-offset-1-desktop is-7-desktop insight-list-element-wrapper">
        {insightData.map((insight, key) => {
          return (
            <InsightListElement
              key={key}
              categoryPath={categoryPath}
              insight={insight}
              index={key}
            />
          );
        })}
      </div>

      {link && (
        <motion.div
          variants={blur_opacity_variant}
          className="column is-12 is-hidden-desktop is-flex is-justify-content-center"
        >
          <Button
            type="primary"
            title={link.title}
            target={link.target}
            href={link.url}
          ></Button>
        </motion.div>
      )}
    </>
  );
};

interface InsightListElementProp {
  insight: PageType;
  index: number;
  categoryPath: string;
}
const InsightListElement = ({ insight }: InsightListElementProp) => {
  const header = insight.acf?.header_insights!;
  const categories = insight._embedded
    ? insight._embedded["wp:term"].flat()
    : [];

  return (
    <motion.div
      variants={blur_opacity_variant}
      className="columns is-mobile  is-multiline m-0 insight-list-element"
    >
      <div className="column is-12 p-0">
        <div className="categories">
          {categories.map((category, i) => {
            return (
              <CustomLink
                key={i}
                href={getRelativePathFromLink(category.acf.link)}
                target="_self"
                disablePrefetch
                className="categories-link"
              >
                <span className="category">{category.name}</span>
              </CustomLink>
            );
          })}
        </div>

        <Link
          prefetch={false}
          className="information"
          href={getRelativePathFromLink(insight.link)}
        >
          <span className="h3 mb-0">
            <strong
              dangerouslySetInnerHTML={{
                __html: sanitize(unlinkWiki(header.title)),
              }}
            ></strong>
          </span>

          <div className="arrow-wrapper">
            <Arrow width={22.06} height={14.58} />
          </div>
        </Link>
      </div>
    </motion.div>
  );
};
