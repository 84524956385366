import dayjs from "dayjs";
import { sanitize } from "isomorphic-dompurify";
import Link from "next/link";
import { PageType } from "../../utils/baseTypes";
import { unlinkWiki } from "../../utils/unlinkWiki";
import { getRelativePathFromLink } from "../../utils/url";
import Image from "next/image";
import Arrow from "../../images/arrow_dark.svg";
import { CustomLink } from "../Global/CustomLink";
import { LinkNoPrefetch } from "../Global/LinkNoPrefetch";

export const InsightElement = ({
  insight,
  index,
}: {
  insight: PageType;
  index: number;
  categoryPath: string;
}) => {
  const insight_ = insight.acf?.header_insights!;
  const categories = insight._embedded
    ? insight._embedded["wp:term"].flat()
    : [];

  return (
    <div className="insight-element" key={`insight-element-${index}`}>
      <div className="insight-info-wrapper">
        <LinkNoPrefetch
          href={getRelativePathFromLink(insight.link)}
          className="insight-link"
          aria-label="insight-link"
        >
          <div className="image-wrapper">
            {insight_.preview_image && (
              <Image
                src={insight_.preview_image.url!}
                alt={insight_.preview_image.alt}
                data-title={insight_.preview_image.title}
                fill
                style={{ objectFit: "cover" }}
              />
            )}
          </div>
        </LinkNoPrefetch>

        <div className="insight-info">
          <div className="categories">
            {categories.map((category, i) => {
              return (
                <CustomLink
                  key={i}
                  href={category.acf.link}
                  target="_self"
                  disablePrefetch
                >
                  <span className="category">{category.name}</span>
                </CustomLink>
              );
            })}
          </div>
          <LinkNoPrefetch
            href={getRelativePathFromLink(insight.link)}
            className="insight-link"
            aria-label="insight-link"
          >
            <span
              className="h3 title"
              dangerouslySetInnerHTML={{
                __html: sanitize(unlinkWiki(insight_.title)),
              }}
            ></span>
          </LinkNoPrefetch>
        </div>
      </div>

      <div className="autor-info">
        <span className="info">
          <span>
            {insight_.show_updated_datum
              ? insight_.updated_datum
              : dayjs(insight.date_gmt).format("DD.MM.YYYY")}
          </span>
          &nbsp;|&nbsp;
          <span>
            Autor:&nbsp;
            <Link href={`/autor/${insight_.author.post_name}`} prefetch={false}>
              {insight_.author.post_title}
            </Link>
          </span>
        </span>

        <Link
          prefetch={false}
          href={getRelativePathFromLink(insight.link)}
          className="arrow insight-link"
          aria-label="insight-link"
        >
          <Arrow width={22.06} height={14.58} />
        </Link>
      </div>
    </div>
  );
};
