import { GetStaticPropsContext } from "next";
import {
  CategoryDataType,
  PageType,
  PagenationDataType,
  isAvailable,
} from "../../utils/baseTypes";
import { InsightsModuleType } from "./Insights";
import { useEffect, useRef, useState } from "react";
import { useInView, motion, AnimatePresence } from "framer-motion";
import Link from "next/link";
import { WP_Term } from "wp-types";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { getDataArray } from "../../utils/getData";
import { defaultTransition } from "../../utils/transition";
import { getRelativePathFromLink } from "../../utils/url";
import { Button } from "../Global/Button";
import { InsightElementBig } from "./InsightElementBig";
import { Pagenation } from "../Global/Pagenation";
import { InsightElement } from "./InsightElement";
import { take } from "lodash";
import { CustomLink } from "../Global/CustomLink";
import { LoadMore } from "../Global/LoadMore";
import { useRouter } from "next/router";
import { LoadMorePagination } from "../Global/LoadMorePagination";
import { StickyLoader } from "../../Loader/Loader";
import useWindowSize from "../../hooks/useWindowSize";
import { makeOddEven } from "../../utils/makeOddEven";

interface InsightGridProp {
  data: InsightsModuleType;
  InsightData: PagenationDataType;
  InsightCategoryData: CategoryDataType[];
  context: GetStaticPropsContext;
  pageType: string;
  pageId: number;
  InsightHighlightedPosts: PageType[];
}

const element_variant = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: defaultTransition,
  },
  exit: {
    opacity: 0,
    x: "100%",
    transition: defaultTransition,
  },
};

const category_variant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export const InsightGrid = ({
  data,
  InsightData,
  InsightCategoryData,
  context,
  pageId,
  pageType,
  InsightHighlightedPosts,
}: InsightGridProp) => {
  // const [page, setPage] = useState<number>(1);
  const [categoryActive, setCategoryActive] = useState<string>("0");
  const [loading, setLoading] = useState<boolean>(false);
  const [animationState, setAnimationState] = useState<
    "loading" | "idle" | "hide" | "onhover" | "endhover"
  >("idle");
  const [insightData, setInsightData] = useState<PageType[]>([]);
  const [categoryPath, setCategoryPath] = useState<string>("");
  const isBigGrid = data.insights.insights_type === "big-grid";

  const { background } = data.layout;

  const { query } = useRouter();
  const pagetype = query["pt"] ? query["pt"] : null;
  const page =
    pagetype === "insights" ? (query["page"] ? Number(query["page"]) : 1) : 1;

  const { width } = useWindowSize();

  const isTablet = width > 768 && width < 1216;
  const showFour = !isBigGrid && isTablet;

  useEffect(() => {
    if (InsightCategoryData.length > 0) {
      const first = InsightCategoryData[0].link.replace(/\/$/, "");
      const path = first.substring(0, first.lastIndexOf("/"));
      setCategoryPath(path);
    }
  }, [InsightCategoryData]);

  const [allPostInfo, setallPostInfo] = useState<{
    total_pages: number;
    total_posts: number;
  }>({
    total_pages: 0,
    total_posts: 0,
  });

  const [prevHeight, setPrevHeight] = useState<number>(0);
  const [totalLoaded, setTotalLoaded] = useState<number>(0);

  const elementsContRef = useRef<HTMLDivElement>(null);
  const categoryContRef = useRef<HTMLDivElement>(null);

  const inviewElements = useInView(elementsContRef);
  const inviewCategory = useInView(categoryContRef);

  const {
    categories_on,
    posts_to_load: p_to_load,
    grid_type,
    link,
    posts_per_page: p_per_page,
    categories,
    highlight_posts,
  } = data.insights;

  const posts_per_page = Number(p_per_page);
  const posts_to_load = showFour ? 4 : p_to_load;

  const insight_categories = isAvailable<WP_Term[]>(categories)
    ? categories
    : null;

  const hasPostsToLoad =
    grid_type === "pagination" || grid_type === "pagination_load_more"
      ? page * posts_per_page < allPostInfo.total_posts
      : totalLoaded < allPostInfo.total_posts;

  useEffect(() => {
    const toShow = showFour
      ? InsightData.posts
      : take(InsightData.posts, InsightData.posts.length - 1);

    if (grid_type !== "pagination") {
      if (toShow.length === InsightHighlightedPosts.length) {
        setInsightData(InsightHighlightedPosts);
        setTotalLoaded(0);
      } else if (InsightHighlightedPosts.length < toShow.length) {
        const taken = take(
          toShow,
          toShow.length - InsightHighlightedPosts.length
        );
        setInsightData(InsightHighlightedPosts.concat(taken));
        setTotalLoaded(taken.length);
      } else {
        setInsightData(toShow);
        setTotalLoaded(toShow.length);
      }
    } else {
      setInsightData(toShow);
      setTotalLoaded(toShow.length);
    }

    setallPostInfo({
      total_pages: InsightData.total_pages,
      total_posts: InsightData.total_posts,
    });

    if (elementsContRef.current) {
      setPrevHeight(elementsContRef.current.offsetHeight);
    }

    if (insight_categories) {
      const cat_id = insight_categories.map((cat) => cat.term_id).join(",");
      setCategoryActive(cat_id);
    } else {
      setCategoryActive("0");
    }

    if (pagetype === null || pagetype === "insights") {
      if (page !== 1) {
        switch (grid_type) {
          case "pagination":
          case "pagination_load_more":
            loadMorePage(false);
            break;
          case "load-more":
            loadMore();
            break;
          default:
            break;
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFour, InsightData]);

  const loadMorePage = async (enableScroll: boolean) => {
    if (elementsContRef.current) {
      setPrevHeight(elementsContRef.current.offsetHeight);
    }

    setLoading(true);
    let exclude = pageType === "insights" ? `&exclude=${pageId}` : "";
    const highlight_ids = Array.isArray(highlight_posts)
      ? highlight_posts.map((x) => x.ID).join(",")
      : "";

    exclude =
      exclude.length > 0
        ? `${exclude},${highlight_ids}`
        : `&exclude=${highlight_ids}`;

    let url = `/insights?_embed=wp:term&per_page=${posts_per_page}&page=${page}${exclude}`;

    if (categoryActive !== "0") {
      url += `&insight-category=${categoryActive}`;
    }
    const { insight_posts, headers } = await getDataArray(
      url,
      context.locale,
      context.defaultLocale
    ).then((res) => {
      return {
        insight_posts: res.pageData,
        headers: res.headers,
      };
    });

    const total_posts = parseInt(headers.get("X-WP-Total")!);
    const total_pages = parseInt(headers.get("X-WP-TotalPages")!);

    setallPostInfo({
      total_posts,
      total_pages,
    });

    setInsightData(insight_posts);

    if (enableScroll) {
      if (categoryContRef.current && !inviewCategory) {
        categoryContRef.current.scrollIntoView({
          behavior: "smooth",
        });
        setLoading(false);
        return;
      }

      if (elementsContRef.current && !inviewElements) {
        elementsContRef.current.scrollIntoView({
          behavior: "smooth",
        });
        setLoading(false);
        return;
      }
    }

    setLoading(false);
  };

  const loadMore = async () => {
    setAnimationState("loading");

    let exclude = pageType === "insights" ? `&exclude=${pageId}` : "";

    const highlight_ids = Array.isArray(highlight_posts)
      ? highlight_posts.map((x) => x.ID).join(",")
      : "";

    exclude =
      exclude.length > 0
        ? `${exclude},${highlight_ids}`
        : `&exclude=${highlight_ids}`;

    const loaded = insightData.length;
    const isToShow =
      Number(posts_to_load) * (page - 1) === loaded
        ? null
        : Number(posts_to_load) * (page - 1);

    const fields = `&_fields=id,link,date_gmt,_embedded,_links,acf.header_insights.preview_image,acf.header_insights.title,acf.header_insights.excerpt,acf.header_insights.show_updated_datum,acf.header_insights.updated_datum,acf.header_insights.author.post_name,acf.header_insights.author.post_title`;
    let url = `/insights?_embed=wp:term&per_page=${
      isToShow ? isToShow : posts_to_load
    }&offset=${totalLoaded}${exclude}${fields}`;

    if (categoryActive !== "0") {
      url += `&insight-category=${categoryActive}`;
    }
    const { insight_posts, headers } = await getDataArray(
      url,
      context.locale,
      context.defaultLocale
    ).then((res) => {
      return {
        insight_posts: res.pageData,
        headers: res.headers,
      };
    });
    const total_posts = parseInt(headers.get("X-WP-Total")!);
    const newLoaded = Array.isArray(insight_posts)
      ? insight_posts.length + loaded
      : 0;

    await setInsightData((last) => last.concat(insight_posts));
    setTotalLoaded(newLoaded);

    if (total_posts === newLoaded) {
      setAnimationState("hide");
      return;
    }

    setAnimationState("idle");
  };

  useUpdateEffect(() => {
    if (pagetype === null || pagetype === "insights") {
      switch (grid_type) {
        case "pagination":
        case "pagination_load_more":
          loadMorePage(true);
          break;
        case "load-more":
          loadMore();
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      {categories_on && (
        <div ref={categoryContRef} className="column is-12 mb-6">
          <div className="category-grid">
            <motion.span
              variants={category_variant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{
                ...defaultTransition,
                delay: 1,
              }}
            >
              <Link
                prefetch={false}
                href="/insights"
                className={`category-item ${
                  categoryActive === "0" ? "active-cat" : ""
                }`}
              >
                <span className="is-uppercase">All</span>
              </Link>
            </motion.span>

            {InsightCategoryData.map((category, i) => {
              return (
                <motion.span
                  key={i}
                  variants={category_variant}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{
                    ...defaultTransition,
                    delay: i / 10 + 0.5,
                  }}
                >
                  <CustomLink
                    href={getRelativePathFromLink(category.link)}
                    className={`category-item ${
                      categoryActive.includes(String(category.id))
                        ? "active-cat"
                        : ""
                    }`}
                    target="_self"
                    disablePrefetch
                  >
                    <span className="is-uppercase">{category.name}</span>
                  </CustomLink>
                </motion.span>
              );
            })}
          </div>
        </div>
      )}

      <div className="column is-12 insight-elements">
        <motion.div
          variants={element_variant}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="columns is-multiline"
          ref={elementsContRef}
          style={{
            height: loading ? `${prevHeight}px` : "auto",
          }}
        >
          {loading && <StickyLoader />}
          <AnimatePresence>
            {insightData &&
              !loading &&
              insightData.map((insight, i) => {
                // const last = i + (1 % posts_per_page) === 1;

                return (
                  <motion.div
                    key={i}
                    variants={element_variant}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={defaultTransition}
                    className={
                      isBigGrid
                        ? "column is-12"
                        : `column is-12-mobile is-6-tablet is-4-widescreen`
                    }
                  >
                    {isBigGrid ? (
                      <InsightElementBig
                        key={i}
                        insight={insight}
                        index={i}
                        categoryPath={categoryPath}
                      />
                    ) : (
                      <InsightElement
                        key={i}
                        insight={insight}
                        index={i}
                        categoryPath={categoryPath}
                      />
                    )}
                  </motion.div>
                );
              })}
          </AnimatePresence>
        </motion.div>
      </div>

      {grid_type === "cta" && (
        <div className="column is-12 cta-wrapper">
          {link && (
            <Button
              type="primary"
              title={link.title}
              target={link.target}
              href={link.url}
            ></Button>
          )}
        </div>
      )}

      {grid_type === "load-more" && pageType !== "encyclopedia" && (
        <>
          <div className="column is-12 loadmore-wrapper">
            {hasPostsToLoad && (
              <LoadMore
                background={background}
                pageType="insights"
                page={page + 1}
                animationState={animationState}
                setAnimationState={setAnimationState}
              />
            )}
          </div>
        </>
      )}

      {grid_type === "pagination" && (
        <Pagenation
          postsPerPage={posts_per_page}
          postsTotal={allPostInfo.total_posts}
          currentPage={page}
          pageType="insights"
        />
      )}

      {grid_type === "pagination_load_more" && (
        <LoadMorePagination
          postsPerPage={posts_per_page}
          postsTotal={allPostInfo.total_posts}
          currentPage={page}
          pageType="insights"
          hasPostsToLoad={hasPostsToLoad}
        />
      )}
    </>
  );
};
