import { sanitize } from "isomorphic-dompurify";
import { GetStaticPropsContext } from "next";
import { motion } from "framer-motion";
import {
  LayoutType,
  DividerType,
  LinkType,
  PagenationDataType,
  CategoryDataType,
  PageType,
} from "../../utils/baseTypes";
import Divider from "../Divider/Divider";
import { Section } from "../Global/Section";
import { WP_Post, WP_Term } from "wp-types";
import { blur_opacity_variant, stagger_variant } from "../../utils/transition";

import { InsightList } from "./InsightList";
import { InsightGrid } from "./InsightGrid";
import { InsightSlider } from "./InsightSlider";
interface InsightLayout extends LayoutType {
  posts_order: "date" | "random";
}

export type InsightsModuleType = {
  acf_fc_layout: "insights";
  insights: {
    categories_on: boolean;
    headline: string;
    insights_type: "grid" | "slider" | "big-grid" | "list";
    grid_type: "pagination" | "cta" | "load-more" | "pagination_load_more";
    htype_tag: "h2" | "h3";
    link?: LinkType;
    divider: DividerType;
    posts_per_page: string;
    posts_to_load: string;
    categories: WP_Term[] | false;
    list_post_number: string;
    highlight_posts: WP_Post[] | false;
  };
  layout: InsightLayout;
};

interface InsightsProps {
  data: InsightsModuleType;
  InsightData: PagenationDataType;
  InsightCategoryData: CategoryDataType[];
  context: GetStaticPropsContext;
  pageType: string;
  pageId: number;
  InsightHighlightedPosts: PageType[];
}

const Insights = ({
  data,
  InsightData,
  InsightCategoryData,
  InsightHighlightedPosts,
  context,
  pageId,
  pageType,
}: InsightsProps) => {
  const { abstand, background, id } = data.layout;
  const gap = abstand === "default" ? "" : abstand;
  const { divider, insights_type, headline, htype_tag } = data.insights;

  let HeadlineJSX: React.ReactNode;
  switch (htype_tag) {
    case "h2":
      HeadlineJSX = (
        <motion.h2 variants={blur_opacity_variant}>
          <strong
            dangerouslySetInnerHTML={{ __html: sanitize(headline) }}
          ></strong>
        </motion.h2>
      );
      break;
    case "h3":
      HeadlineJSX = (
        <motion.h3 variants={blur_opacity_variant}>
          <strong
            dangerouslySetInnerHTML={{ __html: sanitize(headline) }}
          ></strong>
        </motion.h3>
      );
    default:
      HeadlineJSX = (
        <motion.h2 variants={blur_opacity_variant}>
          <strong
            dangerouslySetInnerHTML={{ __html: sanitize(headline) }}
          ></strong>
        </motion.h2>
      );
      break;
  }

  return (
    <>
      <Section
        id={id}
        disableColumns
        disableContainer
        className={`insights-module divider ${background} ${gap} ${
          insights_type === "slider" ? "insight-slider" : ""
        } `}
        isClipped={false}
      >
        <div className="container">
          <motion.div
            viewport={{ once: true }}
            initial={"initial"}
            whileInView={"animate"}
            variants={stagger_variant}
            className="columns is-multiline"
          >
            {divider.divider_on && (
              <div className="column is-12">
                <Divider data={divider} background={background} />
              </div>
            )}

            {headline &&
              ["grid", "big-grid", "slider"].includes(insights_type) && (
                <div className="column is-12">{HeadlineJSX}</div>
              )}

            {(insights_type === "grid" || insights_type === "big-grid") && (
              <InsightGrid
                data={data}
                InsightData={InsightData}
                context={context}
                InsightCategoryData={InsightCategoryData}
                InsightHighlightedPosts={InsightHighlightedPosts}
                pageId={pageId}
                pageType={pageType}
              />
            )}

            {insights_type === "list" && (
              <InsightList
                Headline={HeadlineJSX}
                InsightData={InsightData}
                data={data}
                InsightCategoryData={InsightCategoryData}
                InsightHighlightedPosts={InsightHighlightedPosts}
              />
            )}
          </motion.div>
        </div>

        {insights_type === "slider" && (
          <div className="columns">
            <InsightSlider
              data={data}
              InsightData={InsightData}
              InsightHighlightedPosts={InsightHighlightedPosts}
              InsightCategoryData={InsightCategoryData}
            />
          </div>
        )}
      </Section>
    </>
  );
};
export default Insights;
